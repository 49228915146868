<template>
  <clientesList />
</template>

<script>
const clientesList = () => import('@/layouts/components/Clientes/ClientesList.vue')

export default {
  components: {
    clientesList,
  },
}
</script>

<style>

</style>
